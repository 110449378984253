import css from './RegNumInput.module.css';
import { Button } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SET_LICENSE_PLATE_INFO } from '../../ducks/currentLicensePlate.duck';
import { useHistory } from 'react-router-dom';

export function RegNumInput(props) {
  const [searchQuery, setSearchQuery] = useState(props.savedRegNum);
  const [licensePlateInfo, setLicensePlateInfo] = useState(null);
  const [cache, setCache] = useState({}); // Step 1: State for caching results
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.currentUser?.id?.uuid);
  const history = useHistory();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (licensePlateInfo?.result) {
      if (props.page === 'searchPage') {
        handleRedirect();
      } else {
        props.updateState(licensePlateInfo);
      }
    }
  }, [licensePlateInfo, props.page]);

  function extractTireMeasurements(str) {
    const tireWidth = str.slice(0, 3);
    const profile = str.match(/\/([^R]*)R/)[1];
    const diameter = str.match(/R([^.]*)\./)[1];
    return { tireWidth, profile, diameter };
  }

  const filterEmptyEntries = valuesArray => valuesArray.filter(value => value !== '');

  function extractValues(tires, prop) {
    const values = tires.map(tire => (tire ? tire[prop] : ''));

    const newSet = [...new Set([...values])];
    const newValues = filterEmptyEntries(newSet);

    return newValues.join('%2C');
  }

  function extractBothValues(front, rear, prop) {
    const frontValues = front.map(tire => (tire ? tire[prop] : ''));
    const rearValues = rear.map(tire => (tire ? tire[prop] : ''));

    const newSet = [...new Set([...frontValues, ...rearValues])];
    const newValues = filterEmptyEntries(newSet);

    return newValues.join('%2C');
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const format = /^[a-zA-Z]{3}\d{2}(\d|[a-zA-Z])$/;

    const query = searchQuery.replace(/[\s-]/g, '');
    if (!format.test(query)) {
      console.error('Invalid format. The input must be in the format "abc123" or "abc12a".');
      return;
    }

    if (cache[query]) {
      console.log('Retrieving data from cache');
      setLicensePlateInfo(cache[query]);
      dispatch({
        type: SET_LICENSE_PLATE_INFO,
        payload: cache[query],
      });
    } else {
      const encodedQuery = encodeURIComponent(query);
      const api = process.env.REACT_APP_API_URL;
      const userIdParam = userId ? `?userId=${encodeURIComponent(userId)}` : '';
      const apiUrl = `${api}/api/proxy/wheelplace/${encodedQuery}${userIdParam}`;

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Step 3: Update cache with the new data
        setCache({
          ...cache,
          [query]: data,
        });
        // Dispatch action here
        dispatch({
          type: SET_LICENSE_PLATE_INFO,
          payload: data,
        });
        setLicensePlateInfo(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
  };

  const extractOriginalTires = tire => {
    const tireReplaced = tire.replace(/[a-zA-Z/\s]/g, '');
    const tireWidth = tireReplaced.slice(0, 3);
    const profile = tireReplaced.slice(3, 5);
    const diameter = tireReplaced.slice(5, 7);

    return { tireWidth, profile, diameter };
  };

  const handleRedirect = () => {
    // const frontWheels = [];
    // const rearWheels = [];

    // licensePlateInfo?.result?.tire_info?.tires.map(set => {
    //   frontWheels.push(set.front);
    //   rearWheels.push(set.rear);
    // });

    // const originalFrontTire = extractOriginalTires(
    //   licensePlateInfo.result.original_tires.front_tires
    // );
    // const originalRearTire = extractOriginalTires(
    //   licensePlateInfo.result.original_tires.rear_tires
    // );

    // const frontTires = frontWheels?.map(wheel => {
    //   return extractTireMeasurements(wheel.tire);
    // });

    // const rearTires = rearWheels?.map(wheel => {
    //   return extractTireMeasurements(wheel.tire);
    // });

    // const allFrontTires = [originalFrontTire, ...frontTires];
    // const allRearTires = [originalRearTire, ...rearTires];

    // let filterParams = {};

    // if (props.listingType === 'tire') {
    //   filterParams = {
    //     profileFront: !extractValues(allFrontTires, 'profile')
    //       ? ''
    //       : `pub_profileFront=${extractValues(allFrontTires, 'profile')}`,
    //     profileRear: !extractValues(allRearTires, 'profile')
    //       ? ''
    //       : `pub_profileRear=${extractValues(allRearTires, 'profile')}`,
    //     tireWidthFront: !extractValues(allFrontTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthFront=${extractValues(allFrontTires, 'tireWidth')}`,
    //     tireWidthRear: !extractValues(allRearTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthRear=${extractValues(allRearTires, 'tireWidth')}`,
    //     diameter: !extractBothValues(allFrontTires, allRearTires, 'diameter')
    //       ? ''
    //       : `pub_diameter=${extractBothValues(allFrontTires, allRearTires, 'diameter')}`,
    //   };
    //   listingTypeKey = 1;
    // } else if (props.listingType === 'rim') {
    //   filterParams = {
    //     diameter: !extractBothValues(allFrontTires, allRearTires, 'diameter')
    //       ? ''
    //       : `pub_diameter=${extractBothValues(allFrontTires, allRearTires, 'diameter')}`,
    //     numberOfBolts: !licensePlateInfo.result.tire_info.bolts
    //       ? ''
    //       : `pub_numberOfBolts=${licensePlateInfo.result.tire_info.bolts}`,
    //     boltCircle: !licensePlateInfo.result.tire_info.bolt_circle
    //       ? ''
    //       : `pub_boltCircle=${licensePlateInfo.result.tire_info.bolt_circle}`,
    //     hubHole: !licensePlateInfo.result.tire_info.center_bore
    //       ? ''
    //       : `pub_hubHole=${licensePlateInfo.result.tire_info.center_bore}`,
    //   };
    //   listingTypeKey = 2;
    // } else {
    //   filterParams = {
    //     profileFront: !extractValues(allFrontTires, 'profile')
    //       ? ''
    //       : `pub_profileFront=${extractValues(allFrontTires, 'profile')}`,
    //     profileRear: !extractValues(allRearTires, 'profile')
    //       ? ''
    //       : `pub_profileRear=${extractValues(allRearTires, 'profile')}`,
    //     tireWidthFront: !extractValues(allFrontTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthFront=${extractValues(allFrontTires, 'tireWidth')}`,
    //     tireWidthRear: !extractValues(allRearTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthRear=${extractValues(allRearTires, 'tireWidth')}`,
    //     diameter: !extractBothValues(allFrontTires, allRearTires, 'diameter')
    //       ? ''
    //       : `pub_diameter=${extractBothValues(allFrontTires, allRearTires, 'diameter')}`,
    //     numberOfBolts: !licensePlateInfo.result.tire_info.bolts
    //       ? ''
    //       : `pub_numberOfBolts=${licensePlateInfo.result.tire_info.bolts}`,
    //     boltCircle: !licensePlateInfo.result.tire_info.bolt_circle
    //       ? ''
    //       : `pub_boltCircle=${licensePlateInfo.result.tire_info.bolt_circle}`,
    //     hubHole: !licensePlateInfo.result.tire_info.center_bore
    //       ? ''
    //       : `pub_hubHole=${licensePlateInfo.result.tire_info.center_bore}`,
    //   };

    const filterParams = {
      boltCircle: !licensePlateInfo.result.tire_info.center_bore
        ? ''
        : `pub_boltCircle=${licensePlateInfo.result.tire_info.bolt_circle}`,
      carBrandCategory: !licensePlateInfo.result.brand
        ? ''
        : `pub_categoryLevel1=${licensePlateInfo.result.brand.toLowerCase()}`,
      numberOfBolts: !licensePlateInfo.result.tire_info.bolts
        ? ''
        : `pub_numberOfBolts=${licensePlateInfo.result.tire_info.bolts}`,
    };

    //Some characters must be replaced due to the config in Sharetribe Console
    const filtersUrl = Object.values(filterParams)
      .join('&')
      .replace(/\./g, '_')
      .replace(/-/g, 'n');

    history.push(`annonser?${filtersUrl}`);

    let listingTypeKey = 0;

    if (props.listingType === 'tire') {
      listingTypeKey = 1;
    } else if (props.listingType === 'rim') {
      listingTypeKey = 2;
    }

    dispatch({
      type: 'SET_LISTINGTYPE',
      payload: listingTypeKey,
    });
  };

  return (
    <>
      <div className={css.searchBoxReg}>
        <input
          type="text"
          placeholder="ABC 123"
          value={searchQuery}
          onChange={event => {
            setSearchQuery(event.target.value.toUpperCase());
          }}
          style={{
            borderRadius: '5px 0 0 5px',
            padding: '0 0 0 30px',
            border: '1px solid #1E2F7B',
          }}
          disabled={props.disabled}
        />

        <Button
          onClick={handleSubmit}
          sx={{
            fontSize: '20px',
            borderRadius: '0 5px 5px 0',
            margin: '0 0 0 0',
            boxShadow: 'none',
            backgroundColor: '#1E2F7B',
          }}
          variant="contained"
          disabled={props.disabled}
        >
          <SearchOutlinedIcon fontSize="large"></SearchOutlinedIcon>
        </Button>
      </div>
    </>
  );
}
